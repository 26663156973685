import {Link, NavLink, useNavigate, useParams} from "react-router-dom";
import "./BlogPost.scss";
import React, {useEffect, useState} from "react";
import {fetchPost} from "../../fetchingAPI/posts.js";
import {Card} from "primereact/card";
import { BreadCrumb } from 'primereact/breadcrumb';

function BlogPost() {

    const { postId } = useParams();


    const [post, setPost] = useState(null);

    const navigate = useNavigate();

    const imageUrl = "/storage/static/pictures-in-blog-post.jpeg";

    const items = [
        {
            label: post?.title,
            template: () => <NavLink to={"/homepage/blog/" + post?.id}>
                <a className="text-primary font-semibold">{post?.title}</a>
            </NavLink>
        }
    ];
    const home =  {
        label: 'Блог',
        template: () => <NavLink to="/homepage/blog">
            <a className="text-primary font-semibold">Блог</a>
        </NavLink>
    };

    const getPost = async() => {
        let result = await fetchPost(postId);
        console.log('result: ' + JSON.stringify(result));
        setPost(result.post);
    }

    const splitTextIntoParagraphs = (elementQuerySelector, text, maxSentences = 6) => {
        const element = document.querySelector(elementQuerySelector);
        element.innerHTML = '';
        if (element) {
            console.log('p text: ' + element?.textContent);
            console.log('text: ' + text);
            const sentences = text.split('.');
            let sentencesCount = 0;
            sentences.forEach((sentence, index) => {
                console.log('sentence: ' + sentence + ' count: ' + sentencesCount);
                if (sentencesCount >= maxSentences - 1) {
                    (index === sentences.length - 1) ? element.innerHTML += sentence + '<br>' : element.innerHTML += sentence + '.<br>';
                    sentencesCount = 0;
                    //const br = document.createElement('br');
                    //element.appendChild(br);
                } else if (sentencesCount === 0) {
                    (index === sentences.length - 1) ? element.innerHTML += '&nbsp&nbsp&nbsp&nbsp' + sentence :  element.innerHTML += '&nbsp&nbsp&nbsp&nbsp' + sentence + '.';
                    sentencesCount += 1;
                } else {
                    (index === sentences.length - 1) ? element.innerHTML += sentence : element.innerHTML += sentence + '.';
                    sentencesCount += 1;
                }
            })
        }

    }

    useEffect(() => {
        if (post) {
            splitTextIntoParagraphs('.blog__post-body p', post?.body);
        }
    }, [post]);

    useEffect(() => {
        getPost();
    }, [postId]);

    useEffect(() => {
        console.log('postId: ' + postId);
        getPost();
    }, []);

    return (
        <div className="blog__post-main">

            <BreadCrumb model={items} home={home} />

            <div className="blog__post-container">

                <Card>
                    <div className="blog__post-image">
                        {post !== null && post !== undefined ?
                            (<img src={post.attachments.length > 0 ? post.attachments.at(-1).url : imageUrl}/>) :
                            (<React.Fragment key={Math.random()}/>) }
                    </div>

                    <div className="blog__post-content">
                        {post !== null && post !== undefined ? (
                            <>
                                <div className="blog__post-title">
                                    <h2>{post.title}</h2>
                                </div>

                                <div className="blog__post-description">
                                    <p>{post.description}</p>
                                </div>

                                <div className="blog__post-body">
                                    <p></p>
                                </div>
                            </>
                        ) : (
                            <React.Fragment key={Math.random()}/>
                        )}

                        <div className="blog__post-nav">

                            {
                                post?.previous !== null ? (
                            <NavLink to={"/homepage/blog/" + post?.previous.id} onClick={() => {
                                navigate("/homepage/blog/" + post?.previous.id);
                            }}>
                                <img className="blog__post-prev-img" src="/storage/static/previous.png"></img>
                                {/*<a className="font-semibold blog__post-prev-link">{post?.previous.title}</a>*/}
                            </NavLink>) :
                                    (<React.Fragment key={Math.random()}/>)
                            }

                            {
                                post?.next !== null ? (
                                <NavLink to={"/homepage/blog/" + post?.next.id} onClick={() => {
                                    navigate("/homepage/blog/" + post?.next.id);
                                }}>
                                    {/*<a className="font-semibold blog__post-next-link">{post?.next.title}</a> */}
                                    <img className="blog__post-prev-img" src="/storage/static/next.png"></img>
                                </NavLink>) :
                                (<React.Fragment key={Math.random()}/>)
                            }
                        </div>

                    </div>


                </Card>


            </div>
        </div>
    )
}

export default BlogPost;
