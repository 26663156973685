import React, {useContext, useEffect, useRef, useState} from "react";
import { useParams } from 'react-router-dom';
import {
    fetchAddToFavourite, fetchIsMeInFavourite,
    fetchMeBlocked,
    fetchRemoveFromFavourite,
    fetchUser
} from '../../fetchingAPI/users.js';


import './Profile.scss'
import ChatProvider from "../Chat/ChatProvider.jsx";
import {FavouriteContext} from "../../context/FavouriteContext.js";
import ProfileHeader from "./ProfileHeader.jsx";
import ProfileGallery from "./Gallery/ProfileGallery.jsx";
import ProfileGalleryRibbon from "./Gallery/ProfileGalleryRibbon.jsx";
import ProfileContent from "./ProfileContent.jsx";
import MyProfile from "./MyProfile.jsx";
import {sendNotify} from "../../chatAPI/laravel-echo.js";
import {getRandomInt} from "../../assets/utility.js";
import {useSelector} from "react-redux";


function Profile({
                     me,
                     activeChatProp,
                     setFavouriteCallback,
                     subscribePrivate,
                     messageData,
                     sendMessageHandler,
                     updateChatMessageStamp,
                }) {
    const { userId } = useParams();

    // console.log(userId);

    const [user, setUser] = useState(null);

    const [activeChat, setActiveChat] = useState(activeChatProp);

    const [inFavourite, setInFavourite] = useState(false);

    const [meInFavourite, setMeInFavourite] = useState(false);

    let favourite  = useContext(FavouriteContext);

    const [meInBan, setMeInBan] = useState(false);

    const { isLogIn } = useSelector(state => state.loginReducer);

    const [showGallery, setShowGallery] = useState(false);


    const fillUserInfo = async () => {
        const result = await fetchUser({ userId });

        if (result.ok) {
            const { user } = result;
            console.log('User:');
            console.log(user);
            setUser(user);
        }
    }

    /**
     * Проверка, находится ли данный пользователь в любимых у текущего
     * @returns {Promise<void>}
     */
    const checkFavouriteUser = async() => {
        let result = false;
        favourite.forEach((user) => {
            if (user.id == userId)
                result = true;
        })
        setInFavourite(result);
    }

    const checkMeBan = async() => {
        if (!isLogIn) {
            setMeInBan(false);
        } else {
            let result = await fetchMeBlocked(userId)
            console.log('checkMeBan: ' + JSON.stringify(result));
            setMeInBan(result.block);
        }

    }

    /**
     * Проверка, находится ли текущий пользователь в избранном у заданного
     * @param userId
     * @returns {Promise<boolean|string>}
     */
    const checkMeInFavourite = async(userId) => {
        const result = await fetchIsMeInFavourite(user?.id);
        console.log('meInFavourite: ' + JSON.stringify(result));
        if (result.ok) {
            setMeInFavourite(true);
            return result.message;
        }
        setMeInFavourite(false);
        return false;
    }


    useEffect(() => {
        console.log('Profile loaded');
        console.log(userId);
        fillUserInfo();
        checkMeBan();
        //setActiveChat(false);

        //checkFavourite();
    }, []);

    useEffect(() => {
        fillUserInfo();
    }, [userId]);

    useEffect(() => {
        setActiveChat(activeChatProp);
    }, [activeChatProp]);


    useEffect(() => {
        checkFavouriteUser();
        checkMeInFavourite(user?.id);
    }, [user])

    useEffect(() => {
        checkFavouriteUser();
        checkMeInFavourite(user?.id);
    }, [favourite]);


    if (!user) {
        return (
            <div className="profile__not-found">
                Пользователь не найден
            </div>
        )
    }

    const activateChat = () => {
        console.log('activateChat');
        setActiveChat(!activeChat);
    }

    const addToFavourite = async() => {
        console.log('addToFavourite');
        const data = {
            user_id: userId
        }
        const result = await fetchAddToFavourite(data);
        if (result.message == 'created' && !meInFavourite) {
            await sendNotify('notify_' + user?.id, 'like_user', getRandomInt(0, 5000), me);
        }
        setInFavourite(true);
        setFavouriteCallback();
    }

    const removeFromFavourite = async() => {
        console.log('removeFromFavourite');
        const data = {
            user_id: userId
        }
        const result = await fetchRemoveFromFavourite(data);
        setInFavourite(false);
        setFavouriteCallback();
    }

    const setFavourite = () => {
        if (!inFavourite)
            addToFavourite();
        else
            removeFromFavourite();
    }

    const handleGalleryShow = () => {
        setShowGallery(true);
    }

    const handleGalleryHide = () => {
        setShowGallery(false);
    }

    const {info} = user;

    console.log(user);
    console.log(info);

    return (
        <>
            {
                <div className="profile">
                    {userId == me?.id ? <MyProfile /> :
                        (
                            <>
                                <ProfileHeader user={user}
                                               info={info}
                                               owner={false}
                                               isChatActive={activeChat}
                                               activateChat={activateChat}
                                               setFavourite={setFavourite}
                                               inFavourite={inFavourite}
                                               meInFavourite={meInFavourite}
                                               isLogIn={isLogIn}
                                />

                                { meInBan ?
                                    <ProfileContent
                                        user={user}
                                        info={info}
                                        meInBan={meInBan}
                                    /> :

                                    activeChat ?
                                        <ChatProvider
                                            me={me}
                                            subscribePrivate={subscribePrivate}
                                            messageData={messageData}
                                            updateChatMessageStamp={updateChatMessageStamp}
                                    /> :  showGallery === true ? (
                                        <ProfileGallery user={user} owner={false} handleGalleryHide={handleGalleryHide}/>
                                    ) : (
                                        <>
                                            <ProfileGalleryRibbon
                                                user={user}
                                                owner={false}
                                                handleGalleryClick={handleGalleryShow}
                                            />
                                            <ProfileContent
                                                user={user}
                                                info={info}
                                                meInBan={meInBan}
                                            />
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            }
        </>
    )
}

export default Profile;

function ProfileItem({ info, name, title }) {
    console.log(name, info, );

    function getValue() {
        const value = info[name];
        if (typeof value === 'number') {
            if (value === 0) {
                return 'нет';
            }
            if (value === 1) {
                return 'да';
            }
        }

        if (typeof value === 'string') {
            if (value.length === 0) {
                return 'Не указано'
            } else {
                return value;
            }
        }

        return 'Не указано';
    }

    return (
        <div className="profile-item">
            <span className="profile-item__title">{title}</span>
            <span className="profile-item__value">{getValue()}</span>
        </div>
    )
}


