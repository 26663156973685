import React, {useEffect, useRef, useState} from "react";
import { Routes, Route, useLocation, useNavigate, Navigate } from "react-router-dom";
import { fetchLogout } from "../../fetchingAPI/authentication.js";
import {fetchFavourite, fetchMe} from "../../fetchingAPI/users.js";
import { showErrorMessage } from "../../assets/utility.js";

import './Home.scss';

import ProfilesList from '../ProfilesList/ProfilesList.jsx'
import Profile from '../Profile/Profile.jsx'
import Filter from '../Filter/Filter.jsx';
import MyProfile from "../Profile/MyProfile.jsx";
import ChatProvider from "../Chat/ChatProvider.jsx";
import Messenger from "../Messenger/Messenger.jsx";
import {FavouriteContext} from "../../context/FavouriteContext.js";
import {leaveChannel, subscribeToPresenceChannel, subscribeToPrivateChannel} from "../../chatAPI/laravel-echo.js";
import {presenceChannelName} from "../../chatAPI/ably-client.js";
import Blog from "../Blog/Blog.jsx";
import BlogPost from "../Blog/BlogPost.jsx";
import {Toast} from "primereact/toast";
import {ToastContextProvider, useToastContext} from "../../context/ToastContext.jsx";
import {markToastAsRead} from "../../fetchingAPI/messages.js";
import {SubscriptionContext} from "../../context/SubscriptionContext.js";
import {fetchSubscription} from "../../fetchingAPI/subscriptions.js";
import Premium from "../Premium/Premium.jsx";
import {Helmet} from "react-helmet";
import PaymentCancel from "../Payments/PaymentCancel.jsx";
import PaymentSuccess from "../Payments/PaymentSuccess.jsx";
import useWindowDimensions from "../../hooks/useWindowDimensions.js";
import LikeNotificationModal from "../Modal/LikeNotificationModal/LikeNotificationModal.jsx";
import Contacts from "../StaticPages/Contacts.jsx";
import About from "../StaticPages/About.jsx";



const bottomProfilesSize = 6;

function Home({showFilters, isLogIn}) {
    const [message, setMessage] = useState(null);

    const navigate = useNavigate();
    const locationApp = useLocation();
    const { height, width } = useWindowDimensions();

    const [subscription, setSubscription] = useState(null);
    const subscriptionValue = {subscription, setSubscription};

    const [favourite, setFavourite] = useState([]);
    const messengerRef = useRef();
    const [me, setMe] = useState(null);
    const [activeChat, setActiveChat] = useState(false);

    const [presenceUsers, setPresenceUsers] = useState([]);
    const [presenceJoinUser, setPresenceJoinUser] = useState({});
    const [presenceLeaveUser, setPresenceLeaveUser] = useState({});
    const [presenceMessageData, setPresenceMessageData] = useState({});

    const [messageData, setMessageData] = useState({});

    const[chatMessageStamp, setChatMessageStamp] = useState(null);

    const toast = useToastContext();

    const [userLikes, setUserLikes] = useState(null);
    const [notificationModalVisible, setNotificationModalVisible] = useState(false);

    // console.log(locationApp.state);

    useEffect(() => {
        if (locationApp.state && locationApp.state.isEmailVerified) {
            console.log(locationApp.state.message);
            showErrorMessage(locationApp.state.message, setMessage);
        }
    }, [locationApp.state]);

    const getSubscription = async () => {
        let result = await fetchSubscription();
        console.log('getSubscription: ' + JSON.stringify(result));
        setSubscription(result.subscription);
    }

    const checkFavourite = async() => {
        const result = await fetchFavourite();
        setFavourite(result.message);
        //console.log('fetchFavourite result: ' + JSON.stringify(result.message));
        //setFavourite(result.message);
    }

    const setFavouriteCallback = async () => {
        console.log('setFavouriteCallback');
        await checkFavourite();
    }

    /**
     * Обработка нажатия профиля в мессенджере
     * @param userId
     */
    const profileClickedCallback = (userId) => {
        console.log('profileClickedCallback userId: ' + userId);
        setActiveChat(true);
        navigate('/homepage/users/' + userId);
    }

    const deactivateChatCallback = () => {
        setActiveChat(false);
    }


    /**
     * Подписка на канал присутствия - данные в виде параметров коллбэк-функций передаются в дочерние компоненты
     */
    const subscribePresence = () => {
        console.log('Subscribing for presence channel..');
        subscribeToPresenceChannel(presenceChannelName,  'PresenceMessageEvent',
            (users) => {
                setPresenceUsers(users);
            },
            (user) => {
                setPresenceJoinUser(user);
            },
            (user) => {
                setPresenceLeaveUser(user);
            },
            (data) => {
                setPresenceMessageData(data);
            });
    }

    /**
     * Подписка пользователя на канал собеседника
     */
    const subscribePrivate = (channelName) => {
        console.log('Subscribing for user private channel.. ' + channelName);
        if (channelName !== null) {
            //console.log('getMe companion: ' + JSON.stringify(companion));
            subscribeToPrivateChannel(channelName,  'PrivateMessageEvent', (data) => {
                handleMessageEvent(data);
            });
        }
    }

    const handleMessageEvent = (data) => {
        console.log('Chat handleMessageEvent data: ' + JSON.stringify(data));
        setMessageData(data);
    }

    const updateChatMessageStamp = () => {
        setChatMessageStamp(Math.random());
    }

    /**
     * Получение информации о себе
     * @returns {Promise<void>}
     */
    const getMe = async () => {
        const response = await fetchMe();
        if (response.ok) {
            setMe(response.user);
        }
    }

    const handleLikeNotification = (sender, attachmentId, attachmentUrl) => {
        toast?.current?.show(({
            severity: 'info',
            sticky: true,
            content: (props) => (
                <div className="toast-wrapper">
                    <img src={attachmentUrl}></img>
                    {sender.name + ' понравилась ваша фотография'}
                </div>
            )
        }));
    }

    const handleLikeUserNotification = (sender, attachmentId, me) => {
        console.log('User ' + JSON.stringify(me) + ' liked you');
        setUserLikes(me);
    }

    useEffect(() => {

        if (userLikes !== null) {
            setNotificationModalVisible(true);
        }

    }, [userLikes]);

    const handleGiftNotification = (sender, giftId, giftUrl) => {
        toast?.current?.show(({
            severity: 'info',
            sticky: true,
            content: (props) => (
                <div className="toast-wrapper">
                    <img src={giftUrl}></img>
                    {sender.name + ' Отправил вам подарок'}
                </div>
            )
        }));
    }

    const handleNotificationMessageEvent = (data) => {
        console.log('notification data: ' + JSON.stringify(data));
        const user = data.user;
        const notificationType = data.notificationType;
        const notificationId = data.notificationId;
        const notificationData = data.notificationData;
        switch (notificationType) {
            case 'like':
                handleLikeNotification(user, notificationId, notificationData);
                break;

            case 'like_user':
                handleLikeUserNotification(user, notificationId, notificationData);
                break;

            case 'gift':
                handleGiftNotification(user, notificationId, notificationData);

            default:
                break;
        }
    }

    const subscribeNotifyChannel = () => {
        const channelName = 'notify_' + me.id;
        console.log('subscribe to : ' + channelName);
        subscribeToPrivateChannel(channelName,  'NotificationMessageEvent', (data) => {
            handleNotificationMessageEvent(data);
        });
    }


    useEffect(() => {
        if (me !== null) {
            subscribeNotifyChannel()
        }
    }, [me]);


    useEffect(() => {
        const main = document.getElementById('main');
        if (main !== null)
            main.style.backgroundImage = "url()";
        getMe();
        getSubscription();
        checkFavourite();
        subscribePresence();

        return () => {
            //leaveChannel(presenceChannelName);
        }
    }, []);


    const WidgetTypes = {
        LIST: 'LIST',
        PROFILE: 'PROFILE',
    };

    const logout = async (evt) => {
        evt.preventDefault();

        const state = await fetchLogout();
        if (state) {
            navigate('/login');
            location.reload();
        }

        console.log('Выйти!');
    }

    const [widgetType, setWidgetType ] = useState(WidgetTypes.LIST);
    const [userId, setUserid] = useState(-1);

    /**
     * Callback для
     **/
    const toggleWidgets = (type, userId) => {
        setUserid(userId);
    }

    const handleLogout = async (evt) => {
        evt.preventDefault();

        const state = await fetchLogout();
        if (state) {
            navigate('/login');
            location.reload();
        }

        console.log('Выйти!');
    }

    /**
     * Обработка нажатий на toast в зависимости от типа содержимого
     * @param message
     * @returns {Promise<void>}
     */
    const handleToastClose = async (message) => {
        console.log('toast clicked message: ' + JSON.stringify(message));
        const text = message.text;
        const textSplit = text.split('_');
        const eventType = textSplit[0];
        switch (eventType) {
            case 'message':
                let messageId = parseInt(textSplit[1]);
                let result = await markToastAsRead({messageId: messageId});
                console.log('markToastAsRead result: ' + JSON.stringify(result));
                break;

            default:
                break;
        }
    }

    const onLikeNotificationModalHide = () => {
        setNotificationModalVisible(false);
    }


    const title = window.location.href.includes('/homepage/me') ?
        "Моя анкета на Itdating – первой онлайн-платформе для знакомств специалистов «айтишников». " :
        window.location.href.includes('/homepage/blog') ?
        "Блог Itdating – лучшие истории любви айтишников " :
        window.location.href.includes('/homepage/users') ? window.location.href.includes('/homepage/users/') ?
            "Анкеты на Itdating сайте для знакомств, все профили девушек и парней" :
            "Itdating сайт – первая онлайн-платформа для знакомств «айтишников»" :
        "Itdating сайт – первая онлайн-платформа для знакомств «айтишников»"
    ;
    const description = window.location.href.includes('/homepage/me') ?
        "Моя анкета на сайте знакомств Itdating. Мои предпочтения, хобби, увлечения. Все, что нужно знать при знакомстве, а остальное будем узнавать вместе."
        :  window.location.href.includes('/homepage/blog') ?
            "Блог Itdating – истории любви наших пар, как развивались отношения, как зарождалась любовь. У кого-то сильная пара, кто-то сыграл свадьбу!" :
            window.location.href.includes('/homepage/users') ? window.location.href.includes('/homepage/users/') ?
                "Анкеты на Itdating сайте для знакомств, все профили девушек и парней. Поиск по многим параметрам интересам и хобби." :
                "первая онлайн-платформа для знакомств «айтишников». Тысячи одиноких девушек и парней найдут здесь людей, друзей и любовь! Знакомства для айтишников Уютная атмосфера, никаких посторонних и только люди!"
        :
        "Itdating сайт – первая онлайн-платформа для знакомств «айтишников». Тысячи одиноких девушек и парней найдут здесь людей, друзей и любовь! Знакомства для айтишников Уютная атмосфера, никаких посторонних и только люди!"

    return (
        <main className="home-main">
            <Toast ref={toast} onRemove={handleToastClose} />
            {message && (
                <div className="main__popup-message">{message}</div>
            )}
            {/*<button onClick={fetchMe} style={{position: 'absolute', padding: '5px 20px'}}>Кто я</button>*/}
            {/*<button className="tmp tmp__btn" onClick={handleLogout}>Выйти</button> */}
            { showFilters && isLogIn ? <Filter /> : <></>}

            <div className="container">

                <div className="main__row home-main__top">
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{title}</title>
                        <meta name="description" content={description}/>
                        <link rel="canonical" href={window.location.href}/>
                    </Helmet>
                    {/*<div className="main__slogan">
                        <h2 className="slogan">Слоган<br />на пару строк</h2>
                    </div> */}
                    <SubscriptionContext.Provider value={subscriptionValue}>
                        <FavouriteContext.Provider value={favourite}>
                            {
                                isLogIn &&
                                !window.location.href.includes('/homepage/blog') &&
                                !window.location.href.includes('/homepage/contacts') &&
                                !window.location.href.includes('/homepage/about') &&
                                !window.location.href.includes('/homepage/premium') &&
                                !window.location.href.includes('/homepage/messenger') &&
                                width > 1010 ?
                                    /* Отображаемые сообщения меняются посредством установки пропса messageData */
                                    <Messenger
                                        me={me}
                                        profileClickedCallback={profileClickedCallback}
                                        chatMessageStamp={chatMessageStamp}
                                        subscribePrivate={subscribePrivate}
                                        presenceUsers={presenceUsers}
                                        presenceJoinUser={presenceJoinUser}
                                        presenceLeaveUser={presenceLeaveUser}
                                        presenceMessageData={presenceMessageData}
                                        messageData={messageData}
                                        chatMessageStamp={chatMessageStamp}
                                    />
                                    :
                                    <></>
                            }

                            <Routes>
                                <Route path="/blog" element={<Blog/>}/>
                                <Route path="/blog/:postId" element={<BlogPost />}/>
                                <Route path="/premium" element={<Premium/>}/>
                                <Route path="/contacts" element={<Contacts/>}/>
                                <Route path="/about" element={<About/>}/>
                                <Route path="/messenger" element={ <Messenger
                                    me={me}
                                    profileClickedCallback={profileClickedCallback}
                                    chatMessageStamp={chatMessageStamp}
                                    subscribePrivate={subscribePrivate}
                                    presenceUsers={presenceUsers}
                                    presenceJoinUser={presenceJoinUser}
                                    presenceLeaveUser={presenceLeaveUser}
                                    presenceMessageData={presenceMessageData}
                                    messageData={messageData}
                                    chatMessageStamp={chatMessageStamp}
                                />}/>
                            </Routes>


                            <div className='home-main__widgets'>

                                <Routes>

                                    {isLogIn ?
                                        <>
                                            <Route path="/users" element={<ProfilesList
                                                deactivateChatCallback={deactivateChatCallback}
                                            />}/>

                                            <Route path="/favourite" element={<ProfilesList
                                                deactivateChatCallback={deactivateChatCallback} favourite={true}
                                            />}/>
                                            <Route path="/me" element={
                                                <MyProfile headerOnly={false} contentOnly={false}>
                                                </MyProfile>
                                            }/>

                                            <Route path="/payment/success" element={<PaymentSuccess/>}/>
                                            <Route path="/payment/cancel" element={<PaymentCancel/>}/>
                                        </> :
                                        <>
                                            <Route path="/users" element={<ProfilesList
                                                deactivateChatCallback={deactivateChatCallback}
                                            />}/>
                                        </>
                                    }


                                    <Route path="/users/:userId" element={<Profile
                                        me={me}
                                        setFavouriteCallback={setFavouriteCallback}
                                        updateChatMessageStamp={updateChatMessageStamp}
                                        activeChatProp={activeChat}
                                        subscribePrivate={subscribePrivate}
                                        messageData={messageData}
                                    />}/>


                                    }


                                </Routes>


                            </div>


                        </FavouriteContext.Provider>
                    </SubscriptionContext.Provider>

                </div>

                {isLogIn ?
                    <ProfilesList
                        deactivateChatCallback={deactivateChatCallback}
                        size={bottomProfilesSize}
                        loadMore={false}
                        header={true}
                    />
                    :
                    <></>
                }

                <LikeNotificationModal
                    visible={notificationModalVisible}
                    onDialogHide={onLikeNotificationModalHide}
                    setFavouriteCallback={setFavouriteCallback}
                    user={userLikes}
                />

            </div>
        </main>
    );
}

export default Home;
